import clsx from "clsx";

const Module = ({
  children,
  theme = "light",
  className = "",
  onClick = () => {},
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        className,
        "w-fit rounded-[100px] border px-4 py-[6px] font-sans text-[13px] font-medium",
        {
          "border-[#e4e3e8] bg-white text-black": theme === "light",
          "border-[#403f46] bg-black text-white": theme === "dark",
        }
      )}
    >
      {children}
    </div>
  );
};

export default Module;
